import { replace } from 'lodash';
import linkifyHtml from 'linkifyjs/html';

export const formattingTextToHTML = (text, isPlainText) => {
  if (!text) return null;

  let textFormated = text.trim();

  if (isPlainText) {
    const phoneNumber = new RegExp(
      /(^|\s|>|:|\()((?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4})/gim,
    );
    textFormated = replace(textFormated, phoneNumber, (match, space, phone) => {
      return (
        space +
        '<a target="_blank" style="text-decoration: none" href="tel:' +
        phone +
        '">' +
        phone.trim() +
        '</a>'
      );
    });

    const hashTagRegEx = new RegExp(/(^|\s|:)(#[A-zÀ-ÿ\d-]+)/gi);
    textFormated = replace(
      textFormated,
      hashTagRegEx,
      (match, space, hashtag) => {
        const hyperlink =
          'https://twitter.com/hashtag/' + hashtag.replace('#', '');
        return (
          space +
          '<a target="_blank" style="text-decoration: none" href="' +
          hyperlink +
          '">' +
          hashtag.trim() +
          '</a>'
        );
      },
    );

    const arobaseTagRegEx = new RegExp(/(^|\s|:)(@[A-zÀ-ÿ\d-]+)/gi);
    textFormated = replace(
      textFormated,
      arobaseTagRegEx,
      (match, space, arobase) => {
        const hyperlink = 'https://twitter.com/' + arobase.replace('@', '');
        return (
          space +
          '<a target="_blank" style="text-decoration: none" href="' +
          hyperlink +
          '">' +
          arobase.trim() +
          '</a>'
        );
      },
    );
  }

  // DOIT ETRE EN DERNIER
  const newLineRegex = new RegExp(/(\r\n|\n)/gm);
  // Doit etre avant le linkify
  textFormated = replace(textFormated, newLineRegex, '<br>');

  // LINKIFY: Ajoute les href (mails et url)
  textFormated = linkifyHtml(textFormated);

  const pStartTag = new RegExp(/(<p[^>]*>)/gm);
  const pEndTag = new RegExp(/(<\/p[^>]*>)/gm);

  // Pour tous les tag <p> => On fixe les margin à 0 et 0.8em + on supprime les éventuelles classes ajoutées manuellement
  textFormated = replace(
    textFormated,
    pStartTag,
    '<p style="margin-block-start: 0;margin-block-end: 0.8em;margin-inline-start: 0px;margin-inline-end: 0px;">',
  );
  textFormated = replace(textFormated, pEndTag, '</p>');

  textFormated = textFormated.trim();

  // Suppression du <br> en fin de texte pour éviter un blanc en fin de texte.
  // const endOfLineBRTag = new RegExp(/(<br>$)/gm);
  // textFormated = replace(textFormated, endOfLineBRTag, '');

  return textFormated;
};
